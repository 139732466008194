module.exports = {
  messages: {
    '(1 hour closure each day between 5am - 6am)': '(1 hour closure each day between 5am - 6am)',
    '(not saved)': '(not saved)',
    '2FA': '2FA',
    '2FA enabled successfully': '2FA enabled successfully',
    '2FA is used to make access to account more secure. Every time you log into the app, you will receive an email with a passcode required to log in.':
      '2FA is used to make access to account more secure. Every time you log into the app, you will receive an email with a passcode required to log in.',
    '3DS required': '3DS required',
    'AWST (UTC+8)': 'AWST (UTC+8)',
    Accept: 'Accept',
    'Accept 3DS': 'Accept 3DS',
    'Access for all': 'Access for all',
    Account: 'Account',
    'Account Public Key': 'Account Public Key',
    'Account Value': 'Account Value',
    'Account Value Over Time': 'Account Value Over Time',
    'Account balance': 'Account balance',
    'Account based on {0} price': ['Account based on ', ['0'], ' price'],
    'Account details': 'Account details',
    'Account issue': 'Account issue',
    'Account security': 'Account security',
    'Account status': 'Account status',
    Accounts: 'Accounts',
    Actions: 'Actions',
    'Active Limits': 'Active Limits',
    Activity: 'Activity',
    'Add card': 'Add card',
    'Add new card': 'Add new card',
    Address: 'Address',
    'All activity': 'All activity',
    'Already have an account?': 'Already have an account?',
    Amount: 'Amount',
    'Amount to top-up': 'Amount to top-up',
    'Apply Filter': 'Apply Filter',
    April: 'April',
    'Are You sure you want to delete this payment card?':
      'Are You sure you want to delete this payment card?',
    'Are you sure you want to log out now?': 'Are you sure you want to log out now?',
    August: 'August',
    Avatar: 'Avatar',
    Balance: 'Balance',
    'Block/Delete account': 'Block/Delete account',
    Browser: 'Browser',
    Buy: 'Buy',
    'Buy Gold': 'Buy Gold',
    'Buy gold': 'Buy gold',
    CVC: 'CVC',
    "Can't be used (expired)": "Can't be used (expired)",
    "Can't update spot price": "Can't update spot price",
    Cancel: 'Cancel',
    Canceled: 'Canceled',
    'Canceled orders': 'Canceled orders',
    'Card Holder': 'Card Holder',
    'Card Number': 'Card Number',
    'Card successfully added.': 'Card successfully added.',
    'Card was removed.': 'Card was removed.',
    'Cc/Bcc, From': 'Cc/Bcc, From',
    'Certificate of Ownership': 'Certificate of Ownership',
    'Certificate of ownership': 'Certificate of ownership',
    'Certificate of ownership not available': 'Certificate of ownership not available',
    'Choose Account to Top up': 'Choose Account to Top up',
    'Choose Account to Withdraw': 'Choose Account to Withdraw',
    'Choose Your Avatar': 'Choose Your Avatar',
    'Choose destination of transaction': 'Choose destination of transaction',
    'Choose payment method': 'Choose payment method',
    'Clear all accounts (close orders, sell gold, withdraw fiat).':
      'Clear all accounts (close orders, sell gold, withdraw fiat).',
    'Click "OK" to leave WealthStack. We hope to see you back soon.':
      'Click "OK" to leave WealthStack. We hope to see you back soon.',
    'Click here to proceed': 'Click here to proceed',
    Close: 'Close',
    'Coming soon...': 'Coming soon...',
    'Confirm Order': 'Confirm Order',
    'Confirm Transfer': 'Confirm Transfer',
    'Confirm Withdrawal': 'Confirm Withdrawal',
    'Confirm deletion in pop up': 'Confirm deletion in pop up',
    'Congratulations! Now you can start using Wealthstack WXAU.':
      'Congratulations! Now you can start using Wealthstack WXAU.',
    'Consistent growth': 'Consistent growth',
    Continue: 'Continue',
    Costs: 'Costs',
    'Create New Password': 'Create New Password',
    'Create a Password': 'Create a Password',
    'Create an account with Email': 'Create an account with Email',
    'Create your system name?': 'Create your system name?',
    'Current spot price': 'Current spot price',
    DEPOSITING: 'DEPOSITING',
    Dashboard: 'Dashboard',
    Date: 'Date',
    Dated: 'Dated',
    'Debit Cards': 'Debit Cards',
    December: 'December',
    Declined: 'Declined',
    Delete: 'Delete',
    'Delete account': 'Delete account',
    Deposit: 'Deposit',
    'Deposit account': 'Deposit account',
    'Deposit {0} account': ['Deposit ', ['0'], ' account'],
    Deposited: 'Deposited',
    Depositing: 'Depositing',
    'Describe an issue here (min 20, max 250 letters)':
      'Describe an issue here (min 20, max 250 letters)',
    'Destination of Transaction': 'Destination of Transaction',
    'Do you really want to delete user account?': 'Do you really want to delete user account?',
    Documents: 'Documents',
    "Don't have any account yet?": "Don't have any account yet?",
    'Due to the laws and regulations of your country of residence, Wealthstack is obligated to perform identity verification prior to your purchase.':
      'Due to the laws and regulations of your country of residence, Wealthstack is obligated to perform identity verification prior to your purchase.',
    Earned: 'Earned',
    Email: 'Email',
    'Email notification': 'Email notification',
    'Enter Phone #': 'Enter Phone #',
    'Enter code': 'Enter code',
    'Enter recepient ID': 'Enter recepient ID',
    Executed: 'Executed',
    'Executed orders': 'Executed orders',
    Expiration: 'Expiration',
    February: 'February',
    Fiat: 'Fiat',
    'Fiat tokens': 'Fiat tokens',
    'Forgot password?': 'Forgot password?',
    Friday: 'Friday',
    From: 'From',
    'General question': 'General question',
    'Gold Market Closed': 'Gold Market Closed',
    'Gold Market Closed:': 'Gold Market Closed:',
    'Gold Market Trading Hours': 'Gold Market Trading Hours',
    'Gold continues to provide consistent growth for long-term investors':
      'Gold continues to provide consistent growth for long-term investors',
    'Gold market Trading Hours': 'Gold market Trading Hours',
    'Gold plays a pivotal role in the financial monetary system. Wealthstack makes physical gold available to everyone':
      'Gold plays a pivotal role in the financial monetary system. Wealthstack makes physical gold available to everyone',
    'Gold remains a stable currency even during volatile times':
      'Gold remains a stable currency even during volatile times',
    'Help Center': 'Help Center',
    'Help center': 'Help center',
    Hi: 'Hi',
    'Hi {0}!': ['Hi ', ['0'], '!'],
    'Hi {0}, and welcome to the Help Center. Please select the issue you are facing, describe it, and send it to us. We will try to help you within 24 hours.':
      [
        'Hi ',
        ['0'],
        ', and welcome to the Help Center. Please select the issue you are facing, describe it, and send it to us. We will try to help you within 24 hours.',
      ],
    'ID:': 'ID:',
    'Identity verification': 'Identity verification',
    "If you're set on deleting your profile, it's just four quick steps.":
      "If you're set on deleting your profile, it's just four quick steps.",
    'Immediate actions': 'Immediate actions',
    'In Progress': 'In Progress',
    'In progress': 'In progress',
    'Incorrect email or password': 'Incorrect email or password',
    'Information was copied in buffer': 'Information was copied in buffer',
    'Invalid code': 'Invalid code',
    'Invalid code.': 'Invalid code.',
    'Invalid number format.': 'Invalid number format.',
    'Invalid phone format': 'Invalid phone format',
    'It looks like You still have no activities': 'It looks like You still have no activities',
    'It looks like You still have no orders': 'It looks like You still have no orders',
    'It looks like You still have no payment cards':
      'It looks like You still have no payment cards',
    'It looks like You still have no transactions': 'It looks like You still have no transactions',
    'It looks like you still have an empty list of recipients.':
      'It looks like you still have an empty list of recipients.',
    'It seems you have no cards yet': 'It seems you have no cards yet',
    'It seems {0} already exist. Would you like to': [
      'It seems ',
      ['0'],
      ' already exist. Would you like to',
    ],
    January: 'January',
    July: 'July',
    June: 'June',
    KYC: 'KYC',
    'Legal documents': 'Legal documents',
    'Legal documents that define the conditions for using of wealthstack app. You accepted these documents when you created your user account.':
      'Legal documents that define the conditions for using of wealthstack app. You accepted these documents when you created your user account.',
    "Let's Start": "Let's Start",
    'List of recipients': 'List of recipients',
    'Live pricing error': 'Live pricing error',
    'Live pricing error.': 'Live pricing error.',
    Location: 'Location',
    'Log Out': 'Log Out',
    'Log out': 'Log out',
    'Login With QR code': 'Login With QR code',
    Logout: 'Logout',
    'MM/YY': 'MM/YY',
    'MMM D, YYYY [at] h:mm A': 'MMM D, YYYY [at] h:mm A',
    Maintenance: 'Maintenance',
    March: 'March',
    Market: 'Market',
    Max: 'Max',
    'Max limit': 'Max limit',
    'Max limit {0} {displayedFiatCurrency}': ['Max limit ', ['0'], ' ', ['displayedFiatCurrency']],
    'Max transaction limit': 'Max transaction limit',
    May: 'May',
    'Metal tokens': 'Metal tokens',
    Min: 'Min',
    'Min 8 characters': 'Min 8 characters',
    'Min limit': 'Min limit',
    'Min limit {0} {displayedFiatCurrency}': ['Min limit ', ['0'], ' ', ['displayedFiatCurrency']],
    'Min transaction limit': 'Min transaction limit',
    'Mon - Sat  | 6am - 5am AWST (UTC+8)': 'Mon - Sat  | 6am - 5am AWST (UTC+8)',
    Monday: 'Monday',
    'Name should be min 3 - max 30 letters': 'Name should be min 3 - max 30 letters',
    'New transfer': 'New transfer',
    'Newest to oldest': 'Newest to oldest',
    'Next actions': 'Next actions',
    'Nick Name': 'Nick Name',
    'No 2FA': 'No 2FA',
    'No Avatar': 'No Avatar',
    'No Wallets available': 'No Wallets available',
    'No further action needed; your profile will be deleted automatically.':
      'No further action needed; your profile will be deleted automatically.',
    'No or Poor internet connection': 'No or Poor internet connection',
    'No response from the price service provider': 'No response from the price service provider',
    'Not Verified': 'Not Verified',
    'Not verified': 'Not verified',
    'Note that due to market rate fluctuations, there may be a slight difference in cost of purchase':
      'Note that due to market rate fluctuations, there may be a slight difference in cost of purchase',
    'Note that due to market rate fluctuations, there may be a slight difference in revenue of sell out':
      'Note that due to market rate fluctuations, there may be a slight difference in revenue of sell out',
    'Note that your bank may charge own fees for converting and crediting money into the card account':
      'Note that your bank may charge own fees for converting and crediting money into the card account',
    Notifications: 'Notifications',
    November: 'November',
    'Now, you can use your email and phone number to register a new user profile.':
      'Now, you can use your email and phone number to register a new user profile.',
    OK: 'OK',
    October: 'October',
    Ok: 'Ok',
    'Old Password': 'Old Password',
    'Oldest to newest': 'Oldest to newest',
    'Oops..!': 'Oops..!',
    Order: 'Order',
    Orders: 'Orders',
    'Orders in progress': 'Orders in progress',
    'Other Users will see you under this name. You can change this name anytime in settings.':
      'Other Users will see you under this name. You can change this name anytime in settings.',
    PURCHASE: 'PURCHASE',
    'Page not found': 'Page not found',
    Password: 'Password',
    'Password Recovery': 'Password Recovery',
    'Password must consist of min 8 characters including at least one upper-case character, one number and one special character':
      'Password must consist of min 8 characters including at least one upper-case character, one number and one special character',
    'Password reset': 'Password reset',
    'Password shall consist of min 8 characters, at least one upper-case character, one number, one special character':
      'Password shall consist of min 8 characters, at least one upper-case character, one number, one special character',
    'Password was changed successfully': 'Password was changed successfully',
    'Passwords need to match': 'Passwords need to match',
    'Past transaction ID here (mandatory)': 'Past transaction ID here (mandatory)',
    'Payment Card Deletion': 'Payment Card Deletion',
    'Payment Card Fee': 'Payment Card Fee',
    'Payment Methods': 'Payment Methods',
    'Payment methods': 'Payment methods',
    'Payment provider is not available. Try again': 'Payment provider is not available. Try again',
    Per: 'Per',
    'Period ends': 'Period ends',
    'Period starts': 'Period starts',
    'Personal Information': 'Personal Information',
    'Personal information': 'Personal information',
    'Phone #': 'Phone #',
    'Phone # verification': 'Phone # verification',
    'Phone verification': 'Phone verification',
    'Please Log in to your account': 'Please Log in to your account',
    'Please add card to use it as a destination to withdraw money':
      'Please add card to use it as a destination to withdraw money',
    'Please empty your accounts to activate Deletion button':
      'Please empty your accounts to activate Deletion button',
    'Please enter the valid code': 'Please enter the valid code',
    'Please enter your email to get a code for recovery':
      'Please enter your email to get a code for recovery',
    'Please log in to use the aplication': 'Please log in to use the aplication',
    'Please note that system has MIN and MAX limits for transactions':
      'Please note that system has MIN and MAX limits for transactions',
    'Please provide correct transfer details': 'Please provide correct transfer details',
    'Please remove blank spaces': 'Please remove blank spaces',
    'Please try again': 'Please try again',
    'Please verify with the 6-digits code sent you to':
      'Please verify with the 6-digits code sent you to',
    'Please verify with the 6-digits code sent you to phone':
      'Please verify with the 6-digits code sent you to phone',
    'Please verify yourself to get unlimited access to transactions.':
      'Please verify yourself to get unlimited access to transactions.',
    'Please your phone number to enable Two Factor Authentication. 2FA makes your account more secure.':
      'Please your phone number to enable Two Factor Authentication. 2FA makes your account more secure.',
    Proceed: 'Proceed',
    'Profile settings': 'Profile settings',
    Purchase: 'Purchase',
    'Purchase Price': 'Purchase Price',
    'Purchase of': 'Purchase of',
    Purchased: 'Purchased',
    'Purchases & Redemptions are unavailable right now due to the gold market being closed.':
      'Purchases & Redemptions are unavailable right now due to the gold market being closed.',
    'Push notifications': 'Push notifications',
    Quantity: 'Quantity',
    'Real Name': 'Real Name',
    'Receive important messages about the system or actions directly to your email':
      'Receive important messages about the system or actions directly to your email',
    Received: 'Received',
    'Recent activity': 'Recent activity',
    'Recipient List': 'Recipient List',
    'Recipient Name': 'Recipient Name',
    'Recipient not found': 'Recipient not found',
    Regenerate: 'Regenerate',
    'Required 3D Secure': 'Required 3D Secure',
    'Required 3DS': 'Required 3DS',
    'Requires 3DS': 'Requires 3DS',
    'Resend Code': 'Resend Code',
    'Resend Code in': 'Resend Code in',
    'SALES of GOLD': 'SALES of GOLD',
    Sale: 'Sale',
    'Sale price': 'Sale price',
    'Sales price': 'Sales price',
    'Sat - Mon  |  5am - 6am AWST (UTC+8)': 'Sat - Mon  |  5am - 6am AWST (UTC+8)',
    Saturday: 'Saturday',
    Save: 'Save',
    'Save card': 'Save card',
    'Save recipient': 'Save recipient',
    'Save the card': 'Save the card',
    'Scan QR code with': 'Scan QR code with',
    'Search for Recipient by ID': 'Search for Recipient by ID',
    Security: 'Security',
    'See all activity': 'See all activity',
    'See details': 'See details',
    'See trading hours': 'See trading hours',
    Select: 'Select',
    'Select source and enter amount': 'Select source and enter amount',
    'Select the account to which you would like to receive money':
      'Select the account to which you would like to receive money',
    'Select the currency you want all data to be displayed in. You can change the currency at any time':
      'Select the currency you want all data to be displayed in. You can change the currency at any time',
    'Select the payment card to which you want to withdraw money':
      'Select the payment card to which you want to withdraw money',
    'Select this option for inquiries about "How to" guides, functions, licensing, or any other questions unrelated to specific transactions or account status.':
      'Select this option for inquiries about "How to" guides, functions, licensing, or any other questions unrelated to specific transactions or account status.',
    'Select this option if you have inquiries about a specific transaction. For a prompt response, please provide the transaction ID.':
      'Select this option if you have inquiries about a specific transaction. For a prompt response, please provide the transaction ID.',
    'Select this option if you have questions about registration, account login, KYC, status, security, data history, and more.':
      'Select this option if you have questions about registration, account login, KYC, status, security, data history, and more.',
    Sell: 'Sell',
    'Sell Gold': 'Sell Gold',
    'Sell gold': 'Sell gold',
    'Sell of': 'Sell of',
    Send: 'Send',
    'Send Me Verification Code': 'Send Me Verification Code',
    'Send gold': 'Send gold',
    'Send me a code': 'Send me a code',
    Sent: 'Sent',
    September: 'September',
    'Server response can be restored in a minute': 'Server response can be restored in a minute',
    'Service provider’s live pricing is unresponsive':
      'Service provider’s live pricing is unresponsive',
    'Service server issue': 'Service server issue',
    Settings: 'Settings',
    'Show All orders': 'Show All orders',
    'Show in blockchain': 'Show in blockchain',
    'Show {value} items': ['Show ', ['value'], ' items'],
    'Sign In': 'Sign In',
    'Sign Up': 'Sign Up',
    Sold: 'Sold',
    'Something went wrong': 'Something went wrong',
    'Something went wrong, please try again': 'Something went wrong, please try again',
    'Something went wrong. Please try again in a few minutes.':
      'Something went wrong. Please try again in a few minutes.',
    'Something went wrong. Please try again later.':
      'Something went wrong. Please try again later.',
    "Sorry to see you go. We've got great updates coming next month that you might like.":
      "Sorry to see you go. We've got great updates coming next month that you might like.",
    Spent: 'Spent',
    'Spot Price Trend': 'Spot Price Trend',
    'Spot price not updated': 'Spot price not updated',
    'Stable and Secure': 'Stable and Secure',
    'Start verification': 'Start verification',
    Status: 'Status',
    'Stay informed about the service events even when you are not using the wealthstack app':
      'Stay informed about the service events even when you are not using the wealthstack app',
    Subject: 'Subject',
    Submit: 'Submit',
    'Submit request': 'Submit request',
    Successful: 'Successful',
    Successfull: 'Successfull',
    Sunday: 'Sunday',
    'System account': 'System account',
    'System currency': 'System currency',
    'System is under maintenance, some of operations...':
      'System is under maintenance, some of operations...',
    'System is under maintenance.': 'System is under maintenance.',
    'System limits for depositing.': 'System limits for depositing.',
    'System limits for purchase transactions.': 'System limits for purchase transactions.',
    'System limits for sell out transactions.': 'System limits for sell out transactions.',
    TRANSFER: 'TRANSFER',
    'Tangible value': 'Tangible value',
    'The GOLD price has slightly changed. Please press "Update Order Price" button to reload the new price of this order.':
      'The GOLD price has slightly changed. Please press "Update Order Price" button to reload the new price of this order.',
    'The email is incorrect, Please double check it':
      'The email is incorrect, Please double check it',
    'This field is mandatory': 'This field is mandatory',
    'This field is required.': 'This field is required.',
    'This field should be integer.': 'This field should be integer.',
    'This field should be positive.': 'This field should be positive.',
    'This process typically lasts no longer than 2 hours, after which the system will be fully functional again. Thank you for your patience!':
      'This process typically lasts no longer than 2 hours, after which the system will be fully functional again. Thank you for your patience!',
    'This value is too long. It should have :max characters or less.':
      'This value is too long. It should have :max characters or less.',
    Thursday: 'Thursday',
    To: 'To',
    'To use Wealthstack please read and accept the following documents':
      'To use Wealthstack please read and accept the following documents',
    'Toast notification': 'Toast notification',
    'Toasts can help you keep an eye on your activities and changes in the market. That`s why we advise to keep them on':
      'Toasts can help you keep an eye on your activities and changes in the market. That`s why we advise to keep them on',
    'Top Up': 'Top Up',
    'Top up': 'Top up',
    Total: 'Total',
    'Transact without borders': 'Transact without borders',
    'Transaction Fee': 'Transaction Fee',
    'Transaction ID': 'Transaction ID',
    'Transaction Information': 'Transaction Information',
    'Transaction issue': 'Transaction issue',
    'Transaction period': 'Transaction period',
    'Transaction type': 'Transaction type',
    Transactions: 'Transactions',
    'Transactions history': 'Transactions history',
    'Transactions in Blockchain': 'Transactions in Blockchain',
    'Transactions period': 'Transactions period',
    Transfer: 'Transfer',
    'Transfer Price': 'Transfer Price',
    'Transfer can`t be sent to the Blocked User': 'Transfer can`t be sent to the Blocked User',
    'Transfer can`t be sent to yourself': 'Transfer can`t be sent to yourself',
    'Transfer of': 'Transfer of',
    'Try again': 'Try again',
    Tuesday: 'Tuesday',
    Type: 'Type',
    'Type of Transaction': 'Type of Transaction',
    'Under review': 'Under review',
    'Unfortunately live pricing is unresponsive and no orders to buy/sell gold are available at this time.':
      'Unfortunately live pricing is unresponsive and no orders to buy/sell gold are available at this time.',
    'Unfortunately we can update spot price right now. Please try again in a minute':
      'Unfortunately we can update spot price right now. Please try again in a minute',
    'Unknown error': 'Unknown error',
    'Update Order Price': 'Update Order Price',
    'User ID': 'User ID',
    'User is disabled.': 'User is disabled.',
    Verification: 'Verification',
    Verified: 'Verified',
    Version: 'Version',
    'WITHDRAWAL of FIAT TOKENS': 'WITHDRAWAL of FIAT TOKENS',
    'We are working on restoring the server response and hope to have this rectified soon':
      'We are working on restoring the server response and hope to have this rectified soon',
    "We're continuously enhancing our system to provide you with the best experience. Our technical team is currently upgrading the system, which may temporarily affect some functions.":
      "We're continuously enhancing our system to provide you with the best experience. Our technical team is currently upgrading the system, which may temporarily affect some functions.",
    'Wealthstack currency accounts like an original bank account can be used for Savings, Transfers or as a Payment Method to purchase Gold.':
      'Wealthstack currency accounts like an original bank account can be used for Savings, Transfers or as a Payment Method to purchase Gold.',
    'Wealthstack gold tokens allow you to move your wealth across borders with little to no fees':
      'Wealthstack gold tokens allow you to move your wealth across borders with little to no fees',
    'Wealthstack gold tokens represent real gold stored in high-security vaults around the globe':
      'Wealthstack gold tokens represent real gold stored in high-security vaults around the globe',
    'Wealthstack token based on {0} price': ['Wealthstack token based on ', ['0'], ' price'],
    'Wealthstack version': 'Wealthstack version',
    Wednesday: 'Wednesday',
    'Welcome to': 'Welcome to',
    'Why this order was Canceled ?': 'Why this order was Canceled ?',
    'Why transaction was Canceled ?': 'Why transaction was Canceled ?',
    Withdraw: 'Withdraw',
    Withdrawal: 'Withdrawal',
    'Withdrawal Rate': 'Withdrawal Rate',
    Withdrawn: 'Withdrawn',
    'Wrong card details': 'Wrong card details',
    'Wrong email format': 'Wrong email format',
    'You are successfully Verified': 'You are successfully Verified',
    'You can`t send more than on Your Balance': 'You can`t send more than on Your Balance',
    'You have requested the account deletion. While you are waiting for the approval, all operations are prohibited.':
      'You have requested the account deletion. While you are waiting for the approval, all operations are prohibited.',
    'You need to verify your phone number in order to enable two-factor authentication. That will help improve security and protect your privacy.':
      'You need to verify your phone number in order to enable two-factor authentication. That will help improve security and protect your privacy.',
    'You own': 'You own',
    'Your Phone Number': 'Your Phone Number',
    'Your Wallets': 'Your Wallets',
    'Your account has been activated': 'Your account has been activated',
    'Your account has been blocked': 'Your account has been blocked',
    'Your account will be deleted permanently': 'Your account will be deleted permanently',
    'Your card was declined': 'Your card was declined',
    'Your card was declined.': 'Your card was declined.',
    'Your password needs to meet the requirements': 'Your password needs to meet the requirements',
    'Your profile has been permanently deleted.': 'Your profile has been permanently deleted.',
    'Your verification was Declined': 'Your verification was Declined',
    account: 'account',
    app: 'app',
    blocked: 'blocked',
    'from Other sources': 'from Other sources',
    'from Payment Card': 'from Payment Card',
    'from System Wallets': 'from System Wallets',
    gr: 'gr',
    'gram of physical': 'gram of physical',
    insufficient: 'insufficient',
    kg: 'kg',
    'less than': 'less than',
    'of Transaction Currency': 'of Transaction Currency',
    oz: 'oz',
    sec: 'sec',
    'to Payment Card': 'to Payment Card',
    'to System Wallets': 'to System Wallets',
    wallet: 'wallet',
    'with it?': 'with it?',
    you: 'you',
  },
};
