import './polyfill';
import 'reset-css';
import '@core/di/dependencies';
import 'react-hot-loader';

import React from 'react';

import { appInject } from '@core/di/utils';
import { initializeStateManagement } from '@core/state-management/setup';
import { appCreateTheme } from '@core/theme';
import { AppThemeProvider } from '@core/theme/provider';
import { AppCssBaseline } from '@core/theme/utils/css-baseline';
import { appI18n } from '@core/translation/i18n';
import { AppI18nProvider } from '@core/translation/i18n-provider';
import { StyledEngineProvider } from '@mui/material/styles';
import { LazyLoad } from '@shared/components/lazy-load';
import { RouterProvider } from '@shared/components/router';
import { DI_TOKENS } from '@shared/constants/di';
import { IAuthService } from '@shared/types/auth-service';
import { IConfigService } from '@shared/types/config-service';
import { IHttpClientService } from '@shared/types/http-client';
import { ILocalesVM } from '@shared/types/locales-vm';
import { IProductsVM } from '@shared/types/products-vm';
import { ISmartCoinTrackingService } from '@shared/types/smart-coin-tracking-service';
import { IUsersService } from '@shared/types/users-service';
import { createRoot } from 'react-dom/client';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

import { router } from './ui/routing/routing';

// eslint-disable-next-line import/order
import { ISecure3DRepositoryService } from '@shared/types/secure-3d-repository-service';
// eslint-disable-next-line import/order
import PageError from '@shared/components/page-error';
// eslint-disable-next-line import/order
import { IPaymentProvider } from '@shared/types/payments/payment-provider.interface';

const config = appInject<IConfigService>(DI_TOKENS.configService);
const httpClient = appInject<IHttpClientService>(DI_TOKENS.appHttpClientService);
const authService = appInject<IAuthService>(DI_TOKENS.authService);
const localesVM = appInject<ILocalesVM>(DI_TOKENS.localesVM);

const theme = appCreateTheme();

async function initializeApp() {
  const container = document.getElementById('root') as HTMLElement;
  const root = createRoot(container);
  try {
    initializeStateManagement();
    localesVM.initialize();
    await config.initialize();
    await authService.checkAuthorization();
    httpClient.setConfig({
      refreshToken: authService.refreshToken,
      getAccessToken: () => authService.tokens.access,
      getUserLoginStatus: () => authService.isLoggedIn,
      getTokenRefreshStatus: () => authService.tokenRefreshStatus,
    });

    const paymentService = appInject<IPaymentProvider>(DI_TOKENS.paymentService);
    paymentService.downloadSDK();

    const usersService = appInject<IUsersService>(DI_TOKENS.usersService);
    if (authService.isLoggedIn) {
      await Promise.all([
        usersService.fetchMaintenanceModeStatus(),
        usersService.getMyUserDetails(),
      ]);
    }
    const productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
    await productsVM.downloadProducts();
    const smartCoinStackingService = appInject<ISmartCoinTrackingService>(
      DI_TOKENS.smartCoinTrackingService,
    );
    smartCoinStackingService.init();
    const secure3DRepositoryService = appInject<ISecure3DRepositoryService>(
      DI_TOKENS.secure3DRepositoryService,
    );
    secure3DRepositoryService.removeOldOrders();
    root.render(
      <StyledEngineProvider injectFirst>
        <AppThemeProvider theme={theme}>
          <AppI18nProvider i18n={appI18n}>
            <AppCssBaseline />
            <LazyLoad>
              <RouterProvider router={router} />
            </LazyLoad>
          </AppI18nProvider>
        </AppThemeProvider>
      </StyledEngineProvider>,
    );
  } catch (err) {
    root.render(<PageError />);
    throw err;
  }
}

initializeApp();
