module.exports = {
  messages: {
    '(1 hour closure each day between 5am - 6am)': '-',
    '(not saved)': '-',
    '2FA': '-',
    '2FA enabled successfully': '-',
    '2FA is used to make access to account more secure. Every time you log into the app, you will receive an email with a passcode required to log in.':
      '-',
    '3DS required': '-',
    'AWST (UTC+8)': '-',
    Accept: '-',
    'Accept 3DS': '-',
    'Access for all': '-',
    Account: '-',
    'Account Public Key': '-',
    'Account Value': '-',
    'Account Value Over Time': '-',
    'Account balance': '-',
    'Account based on {0} price': '-',
    'Account details': '-',
    'Account issue': '-',
    'Account security': '-',
    'Account status': '-',
    Accounts: '-',
    Actions: '-',
    'Active Limits': '-',
    Activity: '-',
    'Add card': '-',
    'Add new card': '-',
    Address: '-',
    'All activity': '-',
    'Already have an account?': '-',
    Amount: '-',
    'Amount to top-up': '-',
    'Apply Filter': '-',
    April: '-',
    'Are You sure you want to delete this payment card?': '-',
    'Are you sure you want to log out now?': '-',
    August: '-',
    Avatar: '-',
    Balance: '-',
    'Block/Delete account': '-',
    Browser: '-',
    Buy: '-',
    'Buy Gold': '-',
    'Buy gold': '-',
    CVC: '-',
    "Can't be used (expired)": '-',
    "Can't update spot price": '-',
    Cancel: '-',
    Canceled: '-',
    'Canceled orders': '-',
    'Card Holder': '-',
    'Card Number': '-',
    'Card successfully added.': '-',
    'Card was removed.': '-',
    'Cc/Bcc, From': '-',
    'Certificate of Ownership': '-',
    'Certificate of ownership': '-',
    'Certificate of ownership not available': '-',
    'Choose Account to Top up': '-',
    'Choose Account to Withdraw': '-',
    'Choose Your Avatar': '-',
    'Choose destination of transaction': '-',
    'Choose payment method': '-',
    'Clear all accounts (close orders, sell gold, withdraw fiat).': '-',
    'Click "OK" to leave WealthStack. We hope to see you back soon.': '-',
    'Click here to proceed': '-',
    Close: '-',
    'Coming soon...': '-',
    'Confirm Order': '-',
    'Confirm Transfer': '-',
    'Confirm Withdrawal': '-',
    'Confirm deletion in pop up': '-',
    'Congratulations! Now you can start using Wealthstack WXAU.': '-',
    'Consistent growth': '-',
    Continue: '-',
    Costs: '-',
    'Create New Password': '-',
    'Create a Password': '-',
    'Create an account with Email': '-',
    'Create your system name?': '-',
    'Current spot price': '-',
    DEPOSITING: '-',
    Dashboard: '-',
    Date: '-',
    Dated: '-',
    'Debit Cards': '-',
    December: '-',
    Declined: '-',
    Delete: '-',
    'Delete account': '-',
    Deposit: '-',
    'Deposit account': '-',
    'Deposit {0} account': '-',
    Deposited: '-',
    Depositing: '-',
    'Describe an issue here (min 20, max 250 letters)': '-',
    'Destination of Transaction': '-',
    'Do you really want to delete user account?': '-',
    Documents: '-',
    "Don't have any account yet?": '-',
    'Due to the laws and regulations of your country of residence, Wealthstack is obligated to perform identity verification prior to your purchase.':
      '-',
    Earned: '-',
    Email: '-',
    'Email notification': '-',
    'Enter Phone #': '-',
    'Enter code': '-',
    'Enter recepient ID': '-',
    Executed: '-',
    'Executed orders': '-',
    Expiration: '-',
    February: '-',
    Fiat: '-',
    'Fiat tokens': '-',
    'Forgot password?': '-',
    Friday: '-',
    From: '-',
    'General question': '-',
    'Gold Market Closed': '-',
    'Gold Market Closed:': '-',
    'Gold Market Trading Hours': '-',
    'Gold continues to provide consistent growth for long-term investors': '-',
    'Gold market Trading Hours': '-',
    'Gold plays a pivotal role in the financial monetary system. Wealthstack makes physical gold available to everyone':
      '-',
    'Gold remains a stable currency even during volatile times': '-',
    'Help Center': '-',
    'Help center': '-',
    Hi: '-',
    'Hi {0}!': '-',
    'Hi {0}, and welcome to the Help Center. Please select the issue you are facing, describe it, and send it to us. We will try to help you within 24 hours.':
      '-',
    'ID:': '-',
    'Identity verification': '-',
    "If you're set on deleting your profile, it's just four quick steps.": '-',
    'Immediate actions': '-',
    'In Progress': '-',
    'In progress': '-',
    'Incorrect email or password': '-',
    'Information was copied in buffer': '-',
    'Invalid code': '-',
    'Invalid code.': '-',
    'Invalid number format.': '-',
    'Invalid phone format': '-',
    'It looks like You still have no activities': '-',
    'It looks like You still have no orders': '-',
    'It looks like You still have no payment cards': '-',
    'It looks like You still have no transactions': '-',
    'It looks like you still have an empty list of recipients.': '-',
    'It seems you have no cards yet': '-',
    'It seems {0} already exist. Would you like to': '-',
    January: '-',
    July: '-',
    June: '-',
    KYC: '-',
    'Legal documents': '-',
    'Legal documents that define the conditions for using of wealthstack app. You accepted these documents when you created your user account.':
      '-',
    "Let's Start": '-',
    'List of recipients': '-',
    'Live pricing error': '-',
    'Live pricing error.': '-',
    Location: '-',
    'Log Out': '-',
    'Log out': '-',
    'Login With QR code': '-',
    Logout: '-',
    'MM/YY': '-',
    'MMM D, YYYY [at] h:mm A': '-',
    Maintenance: '-',
    March: '-',
    Market: '-',
    Max: '-',
    'Max limit': '-',
    'Max limit {0} {displayedFiatCurrency}': '-',
    'Max transaction limit': '-',
    May: '-',
    'Metal tokens': '-',
    Min: '-',
    'Min 8 characters': '-',
    'Min limit': '-',
    'Min limit {0} {displayedFiatCurrency}': '-',
    'Min transaction limit': '-',
    'Mon - Sat  | 6am - 5am AWST (UTC+8)': '-',
    Monday: '-',
    'Name should be min 3 - max 30 letters': '-',
    'New transfer': '-',
    'Newest to oldest': '-',
    'Next actions': '-',
    'Nick Name': '-',
    'No 2FA': '-',
    'No Avatar': '-',
    'No Wallets available': '-',
    'No further action needed; your profile will be deleted automatically.': '-',
    'No or Poor internet connection': '-',
    'No response from the price service provider': '-',
    'Not Verified': '-',
    'Not verified': '-',
    'Note that due to market rate fluctuations, there may be a slight difference in cost of purchase':
      '-',
    'Note that due to market rate fluctuations, there may be a slight difference in revenue of sell out':
      '-',
    'Note that your bank may charge own fees for converting and crediting money into the card account':
      '-',
    Notifications: '-',
    November: '-',
    'Now, you can use your email and phone number to register a new user profile.': '-',
    OK: '-',
    October: '-',
    Ok: '-',
    'Old Password': '-',
    'Oldest to newest': '-',
    'Oops..!': '-',
    Order: '-',
    Orders: '-',
    'Orders in progress': '-',
    'Other Users will see you under this name. You can change this name anytime in settings.': '-',
    PURCHASE: '-',
    'Page not found': '-',
    Password: '-',
    'Password Recovery': '-',
    'Password must consist of min 8 characters including at least one upper-case character, one number and one special character':
      '-',
    'Password reset': '-',
    'Password shall consist of min 8 characters, at least one upper-case character, one number, one special character':
      '-',
    'Password was changed successfully': '-',
    'Passwords need to match': '-',
    'Past transaction ID here (mandatory)': '-',
    'Payment Card Deletion': '-',
    'Payment Card Fee': '-',
    'Payment Methods': '-',
    'Payment methods': '-',
    'Payment provider is not available. Try again': '-',
    Per: '-',
    'Period ends': '-',
    'Period starts': '-',
    'Personal Information': '-',
    'Personal information': '-',
    'Phone #': '-',
    'Phone # verification': '-',
    'Phone verification': '-',
    'Please Log in to your account': '-',
    'Please add card to use it as a destination to withdraw money': '-',
    'Please empty your accounts to activate Deletion button': '-',
    'Please enter the valid code': '-',
    'Please enter your email to get a code for recovery': '-',
    'Please log in to use the aplication': '-',
    'Please note that system has MIN and MAX limits for transactions': '-',
    'Please provide correct transfer details': '-',
    'Please remove blank spaces': '-',
    'Please try again': '-',
    'Please verify with the 6-digits code sent you to': '-',
    'Please verify with the 6-digits code sent you to phone': '-',
    'Please verify yourself to get unlimited access to transactions.': '-',
    'Please your phone number to enable Two Factor Authentication. 2FA makes your account more secure.':
      '-',
    Proceed: '-',
    'Profile settings': '-',
    Purchase: '-',
    'Purchase Price': '-',
    'Purchase of': '-',
    Purchased: '-',
    'Purchases & Redemptions are unavailable right now due to the gold market being closed.': '-',
    'Push notifications': '-',
    Quantity: '-',
    'Real Name': '-',
    'Receive important messages about the system or actions directly to your email': '-',
    Received: '-',
    'Recent activity': '-',
    'Recipient List': '-',
    'Recipient Name': '-',
    'Recipient not found': '-',
    Regenerate: '-',
    'Required 3D Secure': '-',
    'Required 3DS': '-',
    'Requires 3DS': '-',
    'Resend Code': '-',
    'Resend Code in': '-',
    'SALES of GOLD': '-',
    Sale: '-',
    'Sale price': '-',
    'Sales price': '-',
    'Sat - Mon  |  5am - 6am AWST (UTC+8)': '-',
    Saturday: '-',
    Save: '-',
    'Save card': '-',
    'Save recipient': '-',
    'Save the card': '-',
    'Scan QR code with': '-',
    'Search for Recipient by ID': '-',
    Security: '-',
    'See all activity': '-',
    'See details': '-',
    'See trading hours': '-',
    Select: '-',
    'Select source and enter amount': '-',
    'Select the account to which you would like to receive money': '-',
    'Select the currency you want all data to be displayed in. You can change the currency at any time':
      '-',
    'Select the payment card to which you want to withdraw money': '-',
    'Select this option for inquiries about "How to" guides, functions, licensing, or any other questions unrelated to specific transactions or account status.':
      '-',
    'Select this option if you have inquiries about a specific transaction. For a prompt response, please provide the transaction ID.':
      '-',
    'Select this option if you have questions about registration, account login, KYC, status, security, data history, and more.':
      '-',
    Sell: '-',
    'Sell Gold': '-',
    'Sell gold': '-',
    'Sell of': '-',
    Send: '-',
    'Send Me Verification Code': '-',
    'Send gold': '-',
    'Send me a code': '-',
    Sent: '-',
    September: '-',
    'Server response can be restored in a minute': '-',
    'Service provider’s live pricing is unresponsive': '-',
    'Service server issue': '-',
    Settings: '-',
    'Show All orders': '-',
    'Show in blockchain': '-',
    'Show {value} items': '-',
    'Sign In': '-',
    'Sign Up': '-',
    Sold: '-',
    'Something went wrong': '-',
    'Something went wrong, please try again': '-',
    'Something went wrong. Please try again in a few minutes.': '-',
    'Something went wrong. Please try again later.': '-',
    "Sorry to see you go. We've got great updates coming next month that you might like.": '-',
    Spent: '-',
    'Spot Price Trend': '-',
    'Spot price not updated': '-',
    'Stable and Secure': '-',
    'Start verification': '-',
    Status: '-',
    'Stay informed about the service events even when you are not using the wealthstack app': '-',
    Subject: '-',
    Submit: '-',
    'Submit request': '-',
    Successful: '-',
    Successfull: '-',
    Sunday: '-',
    'System account': '-',
    'System currency': '-',
    'System is under maintenance, some of operations...': '-',
    'System is under maintenance.': '-',
    'System limits for depositing.': '-',
    'System limits for purchase transactions.': '-',
    'System limits for sell out transactions.': '-',
    TRANSFER: '-',
    'Tangible value': '-',
    'The GOLD price has slightly changed. Please press "Update Order Price" button to reload the new price of this order.':
      '-',
    'The email is incorrect, Please double check it': '-',
    'This field is mandatory': '-',
    'This field is required.': '-',
    'This field should be integer.': '-',
    'This field should be positive.': '-',
    'This process typically lasts no longer than 2 hours, after which the system will be fully functional again. Thank you for your patience!':
      '-',
    'This value is too long. It should have :max characters or less.': '-',
    Thursday: '-',
    To: '-',
    'To use Wealthstack please read and accept the following documents': '-',
    'Toast notification': '-',
    'Toasts can help you keep an eye on your activities and changes in the market. That`s why we advise to keep them on':
      '-',
    'Top Up': '-',
    'Top up': '-',
    Total: '-',
    'Transact without borders': '-',
    'Transaction Fee': '-',
    'Transaction ID': '-',
    'Transaction Information': '-',
    'Transaction issue': '-',
    'Transaction period': '-',
    'Transaction type': '-',
    Transactions: '-',
    'Transactions history': '-',
    'Transactions in Blockchain': '-',
    'Transactions period': '-',
    Transfer: '-',
    'Transfer Price': '-',
    'Transfer can`t be sent to the Blocked User': '-',
    'Transfer can`t be sent to yourself': '-',
    'Transfer of': '-',
    'Try again': '-',
    Tuesday: '-',
    Type: '-',
    'Type of Transaction': '-',
    'Under review': '-',
    'Unfortunately live pricing is unresponsive and no orders to buy/sell gold are available at this time.':
      '-',
    'Unfortunately we can update spot price right now. Please try again in a minute': '-',
    'Unknown error': '-',
    'Update Order Price': '-',
    'User ID': '-',
    'User is disabled.': '-',
    Verification: '-',
    Verified: '-',
    Version: '-',
    'WITHDRAWAL of FIAT TOKENS': '-',
    'We are working on restoring the server response and hope to have this rectified soon': '-',
    "We're continuously enhancing our system to provide you with the best experience. Our technical team is currently upgrading the system, which may temporarily affect some functions.":
      '-',
    'Wealthstack currency accounts like an original bank account can be used for Savings, Transfers or as a Payment Method to purchase Gold.':
      '-',
    'Wealthstack gold tokens allow you to move your wealth across borders with little to no fees':
      '-',
    'Wealthstack gold tokens represent real gold stored in high-security vaults around the globe':
      '-',
    'Wealthstack token based on {0} price': '-',
    'Wealthstack version': '-',
    Wednesday: '-',
    'Welcome to': '-',
    'Why this order was Canceled ?': '-',
    'Why transaction was Canceled ?': '-',
    Withdraw: '-',
    Withdrawal: '-',
    'Withdrawal Rate': '-',
    Withdrawn: '-',
    'Wrong card details': '-',
    'Wrong email format': '-',
    'You are successfully Verified': '-',
    'You can`t send more than on Your Balance': '-',
    'You have requested the account deletion. While you are waiting for the approval, all operations are prohibited.':
      '-',
    'You need to verify your phone number in order to enable two-factor authentication. That will help improve security and protect your privacy.':
      '-',
    'You own': '-',
    'Your Phone Number': '-',
    'Your Wallets': '-',
    'Your account has been activated': '-',
    'Your account has been blocked': '-',
    'Your account will be deleted permanently': '-',
    'Your card was declined': '-',
    'Your card was declined.': '-',
    'Your password needs to meet the requirements': '-',
    'Your profile has been permanently deleted.': '-',
    'Your verification was Declined': '-',
    account: '-',
    app: '-',
    blocked: '-',
    'from Other sources': '-',
    'from Payment Card': '-',
    'from System Wallets': '-',
    gr: '-',
    'gram of physical': '-',
    insufficient: '-',
    kg: '-',
    'less than': '-',
    'of Transaction Currency': '-',
    oz: '-',
    sec: '-',
    'to Payment Card': '-',
    'to System Wallets': '-',
    wallet: '-',
    'with it?': '-',
    you: '-',
  },
};
